<template>
    <div class="yemain">
        <div class="logo">
            <el-image v-if="logo != ''" style="width: 200px; height: 60px" :src="logo"></el-image>
        </div>
        <div class="login" style="width: 800px">
            <span class="login_title login_title_t">商户注册平台</span>
            <span class="login_title login_title_tip">{{ tip }}</span>
            <el-row style="margin: auto">
                <el-col :offset="4" :span="16">
                    <el-form ref="form" size="medium" :label-width="this.env.label_width">
                        <el-form-item label="商户名称">
                            <el-input v-model="company_name"></el-input>
                        </el-form-item>
                        <el-form-item label="类型">
                            <el-radio-group v-model="type">
                                <div style="margin-right: 279px;">
                                    <el-radio :label="1">商户</el-radio>
                                    <el-radio :label="2">个人</el-radio>
                                </div>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="联系人姓名">
                            <el-input v-model="company_contacts"></el-input>
                        </el-form-item>
                        <el-form-item label="联系人电话">
                            <el-input v-model="company_contacts_mobile" maxlength="11"></el-input>
                        </el-form-item>
                        <el-form-item label="密码">
                            <el-input v-model="password" show-password maxlength="26"></el-input>
                        </el-form-item>
                        <el-form-item label="再次输入密码">
                            <el-input v-model="passwords" show-password maxlength="26"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-checkbox v-model="isok" style="margin-right: 10px;" @change="checkeds"></el-checkbox>
                            <span class="margins">阅读并接受
                                <span @click="seeagreement = true" style="color: #409EFF;cursor: pointer;">
                                    《练市集卖家服务协议》
                                </span>
                                <span @click="seeag = true" style="color: #409EFF;cursor: pointer;">
                                    《消费者保障服务协议》
                                </span>
                            </span>
                        </el-form-item>
                        <div class="d-flex">
                            <el-form-item>
                                <el-button v-show="isregister" type="primary" size="medium" @click="register">注册
                                </el-button>
                                <el-button v-show="!isregister" disabled type="primary" size="medium"
                                    style=" margin-left:0px!important;">注册
                                </el-button>
                            </el-form-item>
                            <el-button type="text" size="medium" @click="tosee">返回登录</el-button>
                        </div>
                    </el-form>

                </el-col>
            </el-row>
        </div>

        <el-dialog title="练市集卖家服务协议" :visible.sync="seeagreement" width="80%">
            <div class="editor-content-see" v-html="registeragreement">
            </div>
        </el-dialog>
        <el-dialog title="消费者保障服务协议" :visible.sync="seeag" width="80%">
            <div class="editor-content-see" v-html="privacy">
            </div>
        </el-dialog>

        <div class="footer">
            <span class="footer-sapn">2020-{{ year }} © 练市集 . 管理系统<a class="footer-sapn"
                    style="color: #000; text-decoration:none; margin-left: 20px;" target="_blank"
                    href="http://www.beian.miit.gov.cn">{{ this.env.ICP }}</a><span v-if="this.env.DE_BUG === 'true'"
                    style="color: #cf1322;">【开发测试版】</span></span>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            seeagreement: false,                // 注册协议是否显示
            seeag: false,                         // 注册协议是否显示
            year: new Date().getFullYear(),
            tip: '练市集',
            logo: '',
            registeragreement: "",               // 注册协议
            privacy: "",
            isok: false,
            isregister: false,

            company_name: '',           //接口参数
            company_contacts: '',
            company_contacts_mobile: '',
            password: '',
            passwords: '',
            type: 1,
        }
    },
    // 初始化加载
    created() {
        this.getagreement()
        document.title = '商户注册'
    },
    mounted() {

    },

    methods: {
        //checkeds判断按钮是否禁用
        checkeds(e) {
            if (e) {
                this.isregister = true
            } else {
                this.isregister = false
            }
        },
        //返回登录
        tosee() {
            this.$router.push({ path: '/' })
        },
        // 注册协议
        getagreement() {
            let postdata = {
                api_name: "home.user.registeragreement",
            }
            this.Tool.post_data('merchant', postdata, (json) => {
                if (json.code === 0) {
                    this.registeragreement = json.data.control
                    this.privacy = json.data.privacy
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 商户注册
        register() {
            let postdata = {
                api_name: "home.user.register",
                company_name: this.company_name,
                company_contacts: this.company_contacts,
                company_contacts_mobile: this.company_contacts_mobile,
                password: this.password,
                type: this.type,
            }
            //判断两次密码是否一致
            if (postdata.password != this.passwords) {
                this.$message.error('两次新密码不一样')
                return false
            }
            this.Tool.post_data('merchant', postdata, (json) => {
                if (json.code === 0) {
                    this.$message({
                        message: '注册成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.registerok()
                        }
                    });
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        //返回登录页面
        registerok() {
            this.$router.push('/')
        }
    }

}
</script>

<style scoped>
.margins {
    margin-bottom: 100px;
}

.d-flex {
    display: flex;
    justify-content: space-between;
}

.logo {
    width: auto;
    text-align: center;
}

.yemain {
    min-height: 100vh;
    /* position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; */
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #e9eef3;
    overflow: hidden;
    /*background-image: url("");*/
}

.login {
    background-color: #fff;
    /*box-shadow: 2px 2px 15px #000;*/
    width: 300px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 100px;
    padding: 20px 25px;
    text-align: center;
}

.login_input {
    margin-top: 10px !important;
    width: 100% !important;
}

.login_title {
    display: block;
    font-size: 14px;
    margin: 20px 0;
}

.login_title_t {
    font-size: 24px;
}

.login_title_tip {
    color: #ff9900;
}

.login_but {
    width: 100%;
    margin: 20px 0;
}

.footer {
    z-index: 999;
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: #b3c0d1;
    color: #000;
    /*background: rgba(255, 255, 255, 0.2);*/
    font-size: 14px;
}
</style>
